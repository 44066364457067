import { cn } from "@ui/lib/utils";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}
function PageFlexCol({ children, className }: Props) {
  return (
    <div
      className={cn(
        "flex-grow flex flex-col gap-8 py-8 min-h-fit px-8",
        className
      )}
    >
      {children}
    </div>
  );
}

export { PageFlexCol };
