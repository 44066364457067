"use client"; // Error components must be Client Components

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";
import { PageFlexCol } from "@ui/components/page-flex-col";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <PageFlexCol>
      <section className="flex flex-col md:justify-center items-center h-full">
        <h2 className="text-2xl font-extrabold leading-tight tracking-tighter md:text-3xl py-8">
          Something went wrong!
        </h2>
        <button
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >
          Try again
        </button>
      </section>
    </PageFlexCol>
  );
}
